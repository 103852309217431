import { PropsWithoutRef, RefAttributes } from 'react'
import {
  Value,
  Portal,
  Root,
  ScrollUpButton,
  Group,
  Trigger,
  Content,
  Viewport,
  ScrollDownButton,
} from '@radix-ui/react-select'
import { cva } from 'class-variance-authority'

import ArrowDownIcon from '@snipfeed/icons/ArrowDown'

import type { SelectProps } from '@radix-ui/react-select'

import CaretDownIcon from '../icons/CaretDownIcon'

import Option from './InputSelectOption'

const triggerVariants = cva(
  [
    'px-2 bg-transparent flex items-center justify-between outline-none text-2sx border-r border-gray-400',
    // child icons classes
    'text-gray-700 hover:text-gray-1000',
  ],
  {
    variants: {
      hasError: {
        true: 'border-red-500',
      },
    },
    defaultVariants: {},
  }
)

interface Props extends SelectProps {
  children: React.ReactNode
  placeholder?: string
  hasError?: boolean
}

function Select({ children, placeholder, hasError, ...props }: Props) {
  return (
    <Root {...props}>
      <Trigger className={triggerVariants({ hasError })}>
        <Value placeholder={placeholder} />
        <CaretDownIcon />
      </Trigger>

      <Portal className="z-40">
        <Content className="overflow-hidden bg-white rounded-md shadow-sm">
          <ScrollUpButton />

          <Viewport className="p-1">
            <Group>{children}</Group>
          </Viewport>

          <ScrollDownButton className="flex items-center justify-center	h-6 bg-white cursor-default">
            <ArrowDownIcon width={10} height={10} />
          </ScrollDownButton>
        </Content>
      </Portal>
    </Root>
  )
}

type SelectComponentType = React.ForwardRefExoticComponent<
  PropsWithoutRef<Props> & RefAttributes<HTMLDivElement>
> & {
  Option: typeof Option
}

export default Select as SelectComponentType
