import React, { Ref } from 'react'
import {
  ItemText,
  ItemIndicator,
  SelectItemProps,
  Item,
} from '@radix-ui/react-select'

import Check from '@snipfeed/icons/Check'

interface Props extends SelectItemProps {
  children: React.ReactNode
}

const SelectOption = React.forwardRef(function SelectItem(
  { children, ...props }: Props,
  forwardedRef: Ref<HTMLDivElement>
) {
  return (
    <Item
      className="rounded-sm flex items-center justify-between py-2.5 px-2 relative select-none text-2xs data-[state=disabled]:text-gray-500 data-[state=disabled]:pointer-events-none data-[state=highlighted]:outline-none data-[state=highlighted]:bg-purple-500 data-[state=checked]:bg-gray-50 data-[state=checked]:text-purple-500 hover:bg-gray-50"
      ref={forwardedRef}
      {...props}
    >
      <ItemText>{children}</ItemText>

      <ItemIndicator>
        <Check className="text-purple-500 ml-3" />
      </ItemIndicator>
    </Item>
  )
})

export default SelectOption
