import React from 'react'

export interface ArrowDownProps {
  width?: number
  height?: number
  color?: string
  alt?: string
  className?: string
}

const defaultProps = {
  width: 14,
  height: 8,
  color: '#222222',
  alt: 'ArrowDown',
  className: '',
}

export default function ArrowDown({
  width,
  height,
  color,
  alt,
  className,
}: ArrowDownProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>{alt}</title>
      <path
        d="M12.8334 1.08337L7.00008 6.91671L1.16675 1.08337"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

ArrowDown.defaultProps = defaultProps
