import Link from 'next/link'

import { LogoWordmark } from '@snipfeed/icons'
import Image from '@snipfeed/image'
import { Button, Notification } from '@snipfeed/tint2'

import OnboardingIllustration from '@/public/pictures/account-creation/onboarding-illustration.png'
import CalloutSticker from '@/public/pictures/callout-sticker.png'
import CloudBackgound from '@/public/pictures/cloud-background.png'

export default function AuthLayout({ children }) {
  const navigatorRef = typeof navigator !== 'undefined' && navigator

  return (
    <div className="relative w-full h-full flex flex-col md:flex-row">
      <main className="relative w-full md:overflow-y-auto">
        <div className="w-full p-8">
          <nav className="mb-12">
            <Link aria-label="Logo" href="/">
              <LogoWordmark width={107} height={28} />
            </Link>
          </nav>
          {navigatorRef && !navigatorRef.cookieEnabled && (
            <div className="my-5">
              <Notification
                //@ts-expect-error fix Notification component
                type="error"
                actions={
                  <Link
                    href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Button variant="secondary">Enable cookies</Button>
                  </Link>
                }
                message="Cookies are disabled! Please enable them to continue, Snipfeed requires cookies for login and authentication purposes."
              ></Notification>
            </div>
          )}
          <div className="flex flex-col sm:px-12 items-center justify-center">
            {children}
          </div>
        </div>
      </main>
      {/* Logo and Clouds */}
      <div className="relative w-full flex items-center justify-center py-14 md:overflow-hidden">
        <div className="relative w-full max-w-full flex animate-float delay-[-0.91s] justify-center">
          <Image
            alt={'Join the thousands of creators on Snipfeed'}
            priority
            src={OnboardingIllustration}
            className="max-w-[120%]"
          />
          <div className="absolute sm:flex items-center justify-center rotate-[-7.25deg] right-[-2%] bottom-0 w-1/2 hidden animate-rotateSticker">
            <Image alt={'sticker'} src={CalloutSticker} />
            <div className="absolute flex flex-col w-full leading-5	 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center  italic font-bold tracking-tight rotate-[-10deg]">
              <div>Turn your passion</div>
              <div>into your paycheck</div>
            </div>
          </div>
        </div>

        <div className="absolute left-0 top-0 -z-10 flex h-full w-full overflow-hidden ">
          <Image
            alt="clouds"
            priority
            fill
            style={{ objectFit: 'cover' }}
            src={CloudBackgound}
          />
        </div>
      </div>
    </div>
  )
}
