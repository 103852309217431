import { cva } from 'class-variance-authority'
import React, {
  PropsWithoutRef,
  RefAttributes,
  useImperativeHandle,
  useRef,
} from 'react'

import { cn } from '../../utils/className'
import CloseIcon from '../icons/CloseIcon'

import InputIcon from './InputIcon'
import InputSelect from './InputSelect'

type NativeAttrs = Omit<React.InputHTMLAttributes<any>, keyof Props>

export const defaultProps = {
  disabled: false,
  readOnly: false,
  autoComplete: 'off',
  placeholder: '',
}

export interface Props {
  icon?: React.ReactNode
  iconRight?: React.ReactNode
  label?: React.ReactNode
  error?: boolean
  onClear?: (e: React.MouseEvent<HTMLDivElement>) => void
}

export type InputProps = Props & NativeAttrs

export const inputContainerVariants = cva(
  [
    // default classes
    'inline-flex items-center w-full h-11 border rounded-md bg-white hover:bg-gray-100',
    // desabled classes
    'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:border-disabled disabled:pointer-events-none',
    // animation classes
    'transition duration-50 ease-out hover:ease-in',

    // foxus classes
    'focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-1',
  ],
  {
    variants: {
      disabled: {
        true: '',
      },
      hasError: {
        true: 'border-red-500',
        false: 'border-gray-400 hover:border-gray-600',
      },
    },
    defaultVariants: {},
  }
)

const inputLabelVariants = cva('relative inline-flex h-full items-center', {
  variants: {
    disabled: {
      true: 'pointer-events-none',
    },
  },
  defaultVariants: {},
})

export const inputVariants = cva(
  [
    'text-black text-2xs shadow-none bg-white/0 border-none outline-none rounded-none w-full h-full min-w-0 appearance-none',
    'disabled:cursor-not-allowed disabled:text-gray-500',
    'placeholder-gray-500',
  ],
  {
    variants: {
      isIconLeft: {
        true: 'pl-0',
        false: 'pl-2',
      },
      isIconRight: {
        true: 'pr-0',
        false: 'pr-2',
      },
    },
    defaultVariants: {},
  }
)

const inputClearIconVariants = cva('p-3', {
  variants: {
    isVisible: {
      true: 'visible',
      false: 'invisible',
    },
  },
  defaultVariants: {},
})

const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(
  { icon, iconRight, label, error, className, onClear, ...props }: InputProps,
  ref: React.Ref<HTMLInputElement | null>
) {
  const inputRef = useRef<HTMLInputElement>(null)
  useImperativeHandle(ref, () => inputRef.current)

  return (
    <div className="inline-block w-full border-box">
      <div
        className={inputContainerVariants({
          disabled: props.disabled,
          hasError: error,
        })}
      >
        {label && (
          <div className={inputLabelVariants({ disabled: props.disabled })}>
            {label}
          </div>
        )}
        <div className="inline-flex align-middle items-center h-full flex-1	select-none	rounded-md overflow-hidden">
          {icon && <InputIcon icon={icon} />}

          <input
            type="text"
            ref={inputRef}
            id={props.name}
            className={cn(
              inputVariants({
                isIconLeft: Boolean(icon),
                isIconRight: Boolean(iconRight),
              }),
              className
            )}
            {...props}
          />

          {onClear && (
            <div
              onClick={onClear}
              role="button"
              tabIndex={0}
              className={inputClearIconVariants({
                isVisible: Boolean(props.value !== ''),
              })}
            >
              <CloseIcon className="block" />
            </div>
          )}

          {iconRight && <InputIcon icon={iconRight} />}
        </div>
      </div>
    </div>
  )
})

type InputComponent<T, P> = React.ForwardRefExoticComponent<
  PropsWithoutRef<P> & RefAttributes<T>
> & {
  Select: typeof InputSelect
}

type ComponentProps = Partial<typeof defaultProps> &
  Omit<Props, keyof typeof defaultProps> &
  NativeAttrs

Input.defaultProps = defaultProps

export default Input as InputComponent<HTMLInputElement, ComponentProps>
