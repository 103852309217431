import React from 'react'

interface Props {
  width?: number
  height?: number
  color?: string
  alt?: string
  className?: string
}

const defaultProps = {
  width: 20,
  height: 20,
  color: '#666666',
  alt: 'Checkmark icon',
  className: '',
}

export default function Check({
  color,
  className,
  width,
  height,
  alt,
}: Props): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ minWidth: width }}
      color={color}
    >
      <title>{alt}</title>
      <path
        d="M4.6665 10.4656L8.78415 14.5833L16.3332 5.41663"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

Check.defaultProps = defaultProps
