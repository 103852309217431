import { cva } from 'class-variance-authority'
import CloseIcon from './icons/CloseIcon'
import { InfoCircle, AlertCircle } from '@snipfeed/icons2'
import SuccessIcon from './icons/Success'

export enum NotificationTypes {
  Neutral = 'neutral',
  Success = 'success',
  Informational = 'informational',
  Warning = 'warning',
  Error = 'error',
  Primary = 'primary',
  Dark = 'dark',
}

const notificationTypesToIcon = {
  [NotificationTypes.Neutral]: InfoCircle,
  [NotificationTypes.Success]: SuccessIcon,
  [NotificationTypes.Informational]: InfoCircle,
  [NotificationTypes.Warning]: AlertCircle,
  [NotificationTypes.Error]: AlertCircle,
  [NotificationTypes.Primary]: AlertCircle,
  [NotificationTypes.Dark]: InfoCircle,
}

interface NotificationProps {
  type?: NotificationTypes
  message: string
  onClose?: () => void
  actions?: React.ReactNode
  rectangle?: boolean
}

function NotificationIcon({ type }: { type: NotificationTypes }) {
  const Icon = notificationTypesToIcon[type]

  const iconVariants = cva([], {
    variants: {
      type: {
        [NotificationTypes.Neutral]: ['fill-gray-700', 'text-gray-50'],
        [NotificationTypes.Success]: ['fill-green-500'],
        [NotificationTypes.Informational]: ['fill-blue-500', 'text-blue-150'],
        [NotificationTypes.Warning]: ['fill-brown-500', 'text-brown-150'],
        [NotificationTypes.Error]: ['fill-red-500', 'text-red-150'],
        [NotificationTypes.Primary]: ['fill-purple-500', 'text-purple-150'],
        [NotificationTypes.Dark]: ['fill-white', 'text-black'],
      },
    },
    defaultVariants: {
      type: NotificationTypes.Primary,
    },
  })

  return (
    <div className="mr-4">
      <Icon width={20} height={20} className={iconVariants({ type })} />
    </div>
  )
}

export default function Notification({
  type = NotificationTypes.Primary,
  message,
  onClose,
  actions,
  rectangle,
}: NotificationProps) {
  const notificationVariants = cva(
    [
      // 'z-10',
      'relative',
      'w-full',
      'py-4',
      'rounded-lg',
      'flex',
      'items-center',
      'text-sm',
      'px-3',
      'font-normal',
      'flex-wrap',
      'gap-4',
      'justify-between',
    ],
    {
      variants: {
        type: {
          [NotificationTypes.Neutral]: 'bg-gray-50',
          [NotificationTypes.Success]: 'bg-green-150',
          [NotificationTypes.Informational]: 'bg-blue-150',
          [NotificationTypes.Warning]: 'bg-brown-150',
          [NotificationTypes.Error]: 'bg-red-150',
          [NotificationTypes.Primary]: 'bg-purple-150',
          [NotificationTypes.Dark]: ['text-white', 'bg-black'],
        },
        rectangle: {
          true: ['rounded-none'],
        },
      },
      defaultVariants: {
        type: NotificationTypes.Primary,
      },
    }
  )

  return (
    <div className={notificationVariants({ type, rectangle })}>
      <div className="flex">
        {<NotificationIcon type={type} />}

        <span className="text-2xs">{message}</span>
      </div>

      <div className="cursor-pointer flex items-center">
        {actions}

        {onClose && (
          <div onClick={() => onClose()} className="ml-2">
            <CloseIcon />
          </div>
        )}
      </div>
    </div>
  )
}
