import Input from './Input'
import InputSelect from './InputSelect'
import InputSelectOption from './InputSelectOption'

export type { InputProps } from './Input'

InputSelect.Option = InputSelectOption
Input.Select = InputSelect

export default Input
