import React from 'react'
import NextImage, {
  ImageLoaderProps,
  ImageProps as NextImageProps,
} from 'next/image'

import imageConfig from './config'

const {
  loader: configLoader,
  path: configPath,
  cloudflare: cloudflareConfig,
} = imageConfig

const normalizeSrc = (src: string) => {
  return src[0] === '/' ? src.slice(1) : src
}

const loaders = new Map([['cloudflare', cloudflareLoader]])

function cloudflareLoader({ src, width, quality }: ImageLoaderProps) {
  const params = [`width=${width}`]
  if (quality) {
    params.push(`quality=${quality}`)
  }
  const paramsString = params.join(',')
  return `${
    cloudflareConfig.baseUrl
  }/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`
}

export function constructUrlForVercelDefaultLoader(src: string) {
  const url = new URL(`${configPath}/${normalizeSrc(src)}`)

  return url.href
}

function getCustomImageLoader(loaderProps: ImageLoaderProps) {
  const load = loaders.get(configLoader)

  if (!load) {
    throw new Error('Unrecognized loader passed to Image component')
  }

  return load({ ...loaderProps })
}

const imageLoader = configLoader === 'vercel' ? undefined : getCustomImageLoader

export default function Image({ src, ...all }: NextImageProps): JSX.Element {
  // TODO: Address where and why we get null images
  // (this issue spawned from backgroundStyles images being null)
  if (!src) {
    return <></>
  }

  // Local Images should be imported , so the src is actually an object
  // A String means that it's a remote resource (url or path)
  const isRemoteResource = typeof src === 'string'
  // simple test
  const isUrl =
    typeof src === 'string' &&
    (src.startsWith('https://') || src.startsWith('http://'))

  if (isRemoteResource) {
    //No direct url is set, paths are directly hitting the bucket
    //Construct a URL before passing it to vercel/cloudflare loader
    const constructedUrl = isUrl ? src : constructUrlForVercelDefaultLoader(src)

    return (
      <NextImage
        quality={60}
        src={constructedUrl}
        loader={imageLoader}
        {...all}
      />
    )
  }

  return <NextImage quality={60} src={src} {...all} />
}
