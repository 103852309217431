import { signIn } from 'next-auth/react'

import { GoogleLogo } from '@snipfeed/icons2'
import { Button } from '@snipfeed/tint2'

type GoogleAuthButtonProps = {
  isSignup?: boolean
}

export default function GoogleAuthButton({
  isSignup,
}: GoogleAuthButtonProps): JSX.Element {
  return (
    <Button
      variant="secondary"
      className="w-full mb-6 h-11"
      icon={() => <GoogleLogo className="mr-3" />}
      onClick={async () => {
        const result = await signIn('google', {
          callbackUrl: isSignup
            ? '/signup/your-information'
            : '/check-profile-completion',
        })
        return result
      }}
    >
      {isSignup ? 'Sign up with Google' : 'Login with Google'}
    </Button>
  )
}
