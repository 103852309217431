import NextLink from 'next/link'
import { LinkExternal02 } from '@snipfeed/icons2'
import { cva } from 'class-variance-authority'
import { cn } from '../utils/className'

interface LinkProps extends React.ComponentProps<typeof NextLink> {
  isExternal?: boolean
}

const linkVariants = cva(
  'text-2xs underline text-purple-500 hover:text-purple-600 active:text-purple-700',
  {
    variants: {
      isExternal: {
        true: 'inline-flex items-center',
      },
    },
  }
)

export default function Link({
  children,
  className,
  isExternal,
  ...props
}: LinkProps) {
  return (
    <NextLink
      {...props}
      className={cn(linkVariants({ isExternal }), className)}
    >
      {children}
      {isExternal && <LinkExternal02 width={12} height={12} className="ml-1" />}
    </NextLink>
  )
}
