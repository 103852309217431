interface CaretDownIconProps {
  color?: string
  className?: string
}

export default function CaretDownIcon({
  color,
  className,
}: CaretDownIconProps) {
  return (
    <svg
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      className={className}
    >
      <path
        d="M13.0983 8L6.90169 8C6.15069 8 5.73001 8.75351 6.19399 9.2676L9.29231 12.7006C9.65265 13.0998 10.3474 13.0998 10.7077 12.7006L13.806 9.2676C14.27 8.75351 13.8493 8 13.0983 8Z"
        fill="currentColor"
      />
    </svg>
  )
}
