export default function Success({
  width = 20,
  height = 20,
  className = 'fill-green-500',
}: {
  width?: number
  height?: number
  className?: string
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99998 0.833313C4.93737 0.833313 0.833313 4.93737 0.833313 9.99998C0.833313 15.0626 4.93737 19.1666 9.99998 19.1666C15.0626 19.1666 19.1666 15.0626 19.1666 9.99998C19.1666 4.93737 15.0626 0.833313 9.99998 0.833313ZM14.3392 8.08924C14.6647 7.7638 14.6647 7.23616 14.3392 6.91072C14.0138 6.58529 13.4862 6.58529 13.1607 6.91072L8.74998 11.3215L6.83924 9.41072C6.5138 9.08529 5.98616 9.08529 5.66072 9.41072C5.33529 9.73616 5.33529 10.2638 5.66072 10.5892L8.16072 13.0892C8.48616 13.4147 9.0138 13.4147 9.33924 13.0892L14.3392 8.08924Z"
        className={className}
      />
    </svg>
  )
}
