import React from 'react'

export interface InputIconProps {
  icon: React.ReactNode
}

const InputIcon: React.FC<InputIconProps> = ({ icon }) => {
  return (
    <div className="relative box-content flex w-5 h-full items-center align-middle m-0 px-2 leading-none">
      {icon}
    </div>
  )
}

const MemoInputIcon = React.memo(InputIcon)

export default MemoInputIcon
